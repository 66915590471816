.edlio .legacy.page {
  .main {
    flex: 1 1 auto;
  }

  .sidebar {
    flex: 0 0 23.6%;

    nav {
      font-family: "futura-pt", sans-serif;
      font-size: 16px;
      background: salmon;
      box-shadow: rgba(black, .35) 3px 3px 7px;
      text-decoration: none;
      ul {
        list-style: none;
        padding: 5px 0;

        li {
          a {
            display: block;
            color: white;
            margin-left: 10px;
          }

          &:hover {
            box-shadow: rgba(black, .35) 3px 3px 7px;
            text-decoration: underline;
            background-color: white;
            color: black;
            a {
              color: black;
            }
          }
        }
      }
    }
  }
}
